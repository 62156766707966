<template>
    <div>
        <label class="font-weight-semibold">
            {{ $trans('Answer') }}:
        </label>
        <p class="mb-0"><em>{{ reply }}</em></p>
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'

export default {
    name: 'FreeAnswerResult',
    mixins: [questionsTypeBasePropsMixin],
    computed: {
        isPassed() {
            return this.reply.length > 0
        }
    }
}
</script>

<style lang=scss>

</style>
